<template>
  <div class="fluid-component">
    <video ref="myVideoPlayer">
      <source :src="src" data-fluid-hd type="application/x-mpegURL" />
    </video>
  </div>
</template>

<script>
  import fluidPlayer from "fluid-player";

  export default {
    name: "FluidPlayer",
    props: {
      src: {
        type: String,
        required: true,
      },
      vast_ad: {
        type: [String, null],
        required: true,
      },
      posterImage: {
        type: [String, null],
        required: false,
      },
      autoPlay: {
        type: Boolean,
        required: false,
        default: true
      }
    },
    data() {
      return {
        player: null,
        options: {
          layoutControls: {
            controlBar: {
              autoHideTimeout: 3,
              animated: true,
              autoHide: true,
              playbackRates: ["x2", "x1.5", "x1", "x0.5"],
              nextAndPrev: true,
            },
            htmlOnPauseBlock: {
              html: null,
              height: null,
              width: null,
            },
            autoPlay: this.autoPlay,
            mute: false,
            allowTheatre: true,
            playPauseAnimation: true,
            playbackRateEnabled: true,
            allowDownload: false,
            playButtonShowing: true,
            fillToContainer: true,
            posterImage: this.posterImage,
            keyboardControl: true,
          },
          vastOptions: {
            adList: [
              {
                roll: "preRoll",
                vastTag: this.vast_ad,
                nonlinearDuration: 10,
                adClickable: true,
              },
              {
                roll: "midRoll",
                vastTag: this.vast_ad,
                adText: "",
                adClickable: true,
              },
              {
                roll: "postRoll",
                vastTag: this.vast_ad,
                adText: "",
                adClickable: true,
              },
              {
                roll: "onPauseRoll",
                vastTag: this.vast_ad,
                adText: "",
                adClickable: false,
              },
            ],
            adClickable: false,
            showProgressbarMarkers: true,
            skipButtonCaption: "Skip ad in [seconds]",
            skipButtonClickCaption:
              'Skip ad <span class="skip_button_icon"></span>',
            adText: null,
            adTextPosition: "top left",
            adCTAText: "Visit now!",
            adCTATextPosition: "bottom right",
            vastTimeout: 5000,
            showPlayButton: true, //Default 'bottom right’,
            adCTATextVast: true, // Enabled. To use the CTA text as provided in the VAST XML.
          },
        }
      };
    },
    mounted() {
        this.player = fluidPlayer(this.$refs.myVideoPlayer, this.options);
    },

    destroyed() {
      if (!this.player) {
        return;
      }

      this.player.destroy();
    },
  };
</script>

<style scoped>
  @import "~fluid-player/src/css/fluidplayer.css";

  div.fluid-component,
  div.fluid-component > video {
    width: 100%;
    height: 100%;
  }
</style>
